import React from 'react'
import {
  CFImage,
  CFLink,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { yelp, facebook, instagram, zomato } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView mv="20px" column justifyStart>
          <CFView h3 futura bold color="#C29870" mb="20px">
            SOCIAL MEDIA
          </CFView>
          <CFView row center white bold>
            <CFLink href="https://www.facebook.com/Ki-isu-Japanese-Restaurant-233593898563">
              <CFImage
                w="45px"
                pr="10px"
                src={facebook}
                alt="Ki Isu Sushi Restaurant Facebook"
              />
            </CFLink>
            <CFLink href="https://www.instagram.com/kiisu_japanese/?hl=en">
              <CFImage
                w="45px"
                pr="10px"
                src={instagram}
                alt="Ki Isu Sushi Restaurant Instagram"
              />
            </CFLink>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView mb="13px" mt="15px" column justifyStart>
          <CFView h3 futura bold color="#C29870" mb="15px">
            SOCIAL MEDIA
          </CFView>
          <CFView row justifyStart alignCenter white bold>
            <CFLink href="https://www.facebook.com/Ki-isu-Japanese-Restaurant-233593898563">
              <CFImage
                w="45px"
                pr="10px"
                src={facebook}
                alt="Ki Isu Sushi Restaurant Facebook"
                hover
              />
            </CFLink>
            <CFLink href="https://www.instagram.com/kiisu_japanese/?hl=en">
              <CFImage
                w="45px"
                pr="10px"
                src={instagram}
                alt="Ki Isu Sushi Restaurant Instagram"
                hover
              />
            </CFLink>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
