import React from 'react'
import { Parallax } from 'react-parallax'
import { CFImage, CFView, DefaultScreen, MobileScreen } from 'components'
import { about, aboutParallax, mobileAbout, mobileAboutParallax } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center mt="-1px">
          {/* <CFImage
            src={mobileAboutParallax}
            w="100%"
            pv="5px"
            alt="Ki Isu Sushi Restaurant About"
          /> */}
          <CFImage
            src={mobileAbout}
            w="100%"
            alt="Ki Isu Sushi Restaurant About"
          />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView mt="-1px">
          <CFImage src={about} w="100%" alt="Promotions" />
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
